<template>
  <div class="fragment download-plugins-page-table__fragment">
    <div class="custom-row download-plugins-page-table">

      <!--<template v-if="$store.getters.getLoadingShops === false && $store.getters.getShops.length === 0">-->
        <!--<div class="empty-list "-->
             <!--v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin,-->
             <!--'no-data' : countFilterParams > 0}">-->
          <!--<span class="admin-edit" @click="editTranslate(['common_noResult', 'common_noDataYet', 'common_noResultSeems', 'common_noDataYetTxt', 'common_resetFilters'])"></span>-->
          <!--&lt;!&ndash;{{ $t('notification_noSearchResults.localization_value.value') }}&ndash;&gt;-->

          <!--<div class="empty-list__title" v-if="countFilterParams == 0">-->
            <!--{{$t('common_noResult.localization_value.value')}}-->
          <!--</div>-->
          <!--<div class="empty-list__title" v-else>-->
            <!--{{$t('common_noDataYet.localization_value.value')}}-->
          <!--</div>-->

          <!--<div class="empty-list__txt" v-if="countFilterParams == 0" v-html="$t('common_noResultSeems.localization_value.value')">-->
          <!--</div>-->
          <!--<div class="empty-list__txt" v-else v-html="$t('common_noDataYetTxt.localization_value.value')">-->
          <!--</div>-->

          <!--<div class="empty-list__btn" v-if="countFilterParams > 0">-->
            <!--<MainButton-->
              <!--class="secondary btn-fit-content"-->
              <!--:value="$t('common_resetFilters.localization_value.value')"-->
              <!--@click.native="$emit('resetFilter')"-->
            <!--/>-->
          <!--</div>-->
        <!--</div>-->
      <!--</template>-->

      <div class="download-plugins-page-table__col custom-col custom-col--20 custom-col--xs-100 custom-col--sm-50 custom-col--lg-33 custom-col--xl-25"
        v-if="isAdmin"
      >
        <div class="case-cart">
          <div class="case-cart__inner">
            <div class="case-cart__head">
              <div class="case-cart__title">
                Zebra printer
              </div>
              <div class="case-cart__plugin">
              </div>
            </div>
            <div class="case-cart__body">
              <div class="case-cart__row custom-row">
                <div class="case-cart__col custom-col case-cart__plugin-caption">
                   for win_10 v1.0.0
                </div>
              </div>
              <div class="case-cart__row custom-row">
                <div class="case-cart__col custom-col case-cart__plugin-description">

                </div>
              </div>
            </div>
            <div class="case-cart__bottom">
              <div class="case-cart__btn">

              </div>
              <div class="case-cart__btn"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                      <span class="admin-edit"
                            @click="editTranslate(['plugins_download'])"></span>
                <CaseCartButton
                    :value="$t('plugins_download.localization_value.value')"
                    :ico="true"
                    @click.native="downloadPluginPrinter()"
                >
                  <template slot="ico">
                    <DownloadIco/>
                  </template>
                </CaseCartButton>
              </div>
            </div>
          </div>
        </div>
      </div>

      <template v-if="$store.getters.getDownloadPlugins.plugins && $store.getters.getDownloadPlugins.plugins.opencart">
        <template
          v-if="filter === 0 || filter === 'opencart'"
        >
          <div class="download-plugins-page-table__col custom-col custom-col--20 custom-col--xs-100 custom-col--sm-50 custom-col--lg-33 custom-col--xl-25"
            v-if="$store.getters.getDownloadPlugins.plugins.opencart.orders"
          >
            <div class="case-cart">
              <div class="case-cart__inner">
                <div class="case-cart__head">
                  <div class="case-cart__title"
                       v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <span class="admin-edit"
                          @click="editTranslate(['plugins_order'])"></span>
                    {{ $t('plugins_order.localization_value.value') }}
                  </div>
                  <div class="case-cart__plugin">
                    <img :src="openCartIco" alt="plugin-img">
                  </div>
                </div>
                <div class="case-cart__body">
                  <div class="case-cart__row custom-row">
                    <div class="case-cart__col custom-col case-cart__plugin-caption">
                      {{$store.getters.getDownloadPlugins.plugins.opencart.orders.name}}
                    </div>
                  </div>
                  <div class="case-cart__row custom-row">
                    <div class="case-cart__col custom-col case-cart__plugin-description">
                      {{$t(`${$store.getters.getDownloadPlugins.plugins.opencart.orders.short_description_localization_key}.localization_value.value`)}}
                    </div>
                  </div>
                </div>
                <div class="case-cart__bottom">
                  <div class="case-cart__btn"
                       v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <span class="admin-edit"
                          @click="editTranslate(['plugins_userManual'])"></span>
                    <a target="_blank" :href="$store.getters.getDownloadPlugins.plugins.opencart.orders.user_manuals_link[$store.getters.GET_LANG]">
                      <span class="link-button case-cart__plugin-btn">{{ $t('plugins_userManual.localization_value.value') }}</span>
                    </a>
                  </div>
                  <div class="case-cart__btn"
                       v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <span class="admin-edit"
                          @click="editTranslate(['plugins_download'])"></span>
                    <CaseCartButton
                            :value="$t('plugins_download.localization_value.value')"
                            :ico="true"
                            @click.native="downloadPlugin($store.getters.getDownloadPlugins.plugins.opencart.orders)"
                    >
                      <template slot="ico">
                        <DownloadIco/>
                      </template>
                    </CaseCartButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="download-plugins-page-table__col custom-col custom-col--20 custom-col--xs-100 custom-col--sm-50 custom-col--lg-33 custom-col--xl-25"
            v-if="$store.getters.getDownloadPlugins.plugins.opencart.payments"
          >
            <div class="case-cart">
              <div class="case-cart__inner">
                <div class="case-cart__head">
                  <div class="case-cart__title"
                       v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <span class="admin-edit"
                          @click="editTranslate(['plugins_payments'])"></span>
                    {{ $t('plugins_payments.localization_value.value') }}
                  </div>
                  <div class="case-cart__plugin">
                    <img :src="openCartIco" alt="plugin-img">
                  </div>
                </div>
                <div class="case-cart__body">
                  <div class="case-cart__row custom-row">
                    <div class="case-cart__col custom-col case-cart__plugin-caption">
                      {{$store.getters.getDownloadPlugins.plugins.opencart.payments.name}}
                    </div>
                  </div>
                  <div class="case-cart__row custom-row">
                    <div class="case-cart__col custom-col case-cart__plugin-description">
                      {{$t(`${$store.getters.getDownloadPlugins.plugins.opencart.payments.short_description_localization_key}.localization_value.value`)}}
                    </div>
                  </div>
                </div>
                <div class="case-cart__bottom">
                  <div class="case-cart__btn"
                       v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <span class="admin-edit"
                          @click="editTranslate(['plugins_userManual'])"></span>
                    <a target="_blank" :href="$store.getters.getDownloadPlugins.plugins.opencart.payments.user_manuals_link[$store.getters.GET_LANG]">
                      <span class="link-button case-cart__plugin-btn">{{ $t('plugins_userManual.localization_value.value') }}</span>
                    </a>
                  </div>
                  <div class="case-cart__btn"
                       v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <span class="admin-edit"
                          @click="editTranslate(['plugins_download'])"></span>
                    <CaseCartButton
                            :value="$t('plugins_download.localization_value.value')"
                            :ico="true"
                            @click.native="downloadPlugin($store.getters.getDownloadPlugins.plugins.opencart.payments)"
                    >
                      <template slot="ico">
                        <DownloadIco/>
                      </template>
                    </CaseCartButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </template>

      <template v-if="$store.getters.getDownloadPlugins.plugins && $store.getters.getDownloadPlugins.plugins.woocommerce">
        <template
                v-if="filter === 0 || filter === 'woocommerce'"
        >
          <div class="download-plugins-page-table__col custom-col custom-col--20 custom-col--xs-100 custom-col--sm-50 custom-col--lg-33 custom-col--xl-25"
            v-if="$store.getters.getDownloadPlugins.plugins.woocommerce.orders"
          >
            <div class="case-cart">
              <div class="case-cart__inner">
                <div class="case-cart__head">
                  <div class="case-cart__title"
                       v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <span class="admin-edit"
                          @click="editTranslate(['plugins_order'])"></span>
                    {{ $t('plugins_order.localization_value.value') }}
                  </div>
                  <div class="case-cart__plugin">
                    <img :src="wooСommerceIco" alt="plugin-img">
                  </div>
                </div>
                <div class="case-cart__body">
                  <div class="case-cart__row custom-row">
                    <div class="case-cart__col custom-col case-cart__plugin-caption">
                      {{$store.getters.getDownloadPlugins.plugins.woocommerce.orders.name}}
                    </div>
                  </div>
                  <div class="case-cart__row custom-row">
                    <div class="case-cart__col custom-col case-cart__plugin-description">
                      {{$t(`${$store.getters.getDownloadPlugins.plugins.woocommerce.orders.short_description_localization_key}.localization_value.value`)}}
                    </div>
                  </div>
                </div>
                <div class="case-cart__bottom">
                  <div class="case-cart__btn"
                       v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <span class="admin-edit"
                          @click="editTranslate(['plugins_userManual'])"></span>
                    <a target="_blank" :href="$store.getters.getDownloadPlugins.plugins.woocommerce.orders.user_manuals_link[$store.getters.GET_LANG]">
                      <span class="link-button case-cart__plugin-btn">{{ $t('plugins_userManual.localization_value.value') }}</span>
                    </a>
                  </div>
                  <div class="case-cart__btn"
                       v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <span class="admin-edit"
                          @click="editTranslate(['plugins_download'])"></span>
                    <CaseCartButton
                            :value="$t('plugins_download.localization_value.value')"
                            :ico="true"
                            @click.native="downloadPlugin($store.getters.getDownloadPlugins.plugins.woocommerce.orders)"
                    >
                      <template slot="ico">
                        <DownloadIco/>
                      </template>
                    </CaseCartButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="download-plugins-page-table__col custom-col custom-col--20 custom-col--xs-100 custom-col--sm-50 custom-col--lg-33 custom-col--xl-25"
          v-if="$store.getters.getDownloadPlugins.plugins.woocommerce.payments"
        >
          <div class="case-cart">
            <div class="case-cart__inner">
              <div class="case-cart__head">
                <div class="case-cart__title"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <span class="admin-edit"
                          @click="editTranslate(['plugins_payments'])"></span>
                  {{ $t('plugins_payments.localization_value.value') }}
                </div>
                <div class="case-cart__plugin">
                  <img :src="wooСommerceIco" alt="plugin-img">
                </div>
              </div>
              <div class="case-cart__body">
                <div class="case-cart__row custom-row">
                  <div class="case-cart__col custom-col case-cart__plugin-caption">
                    {{$store.getters.getDownloadPlugins.plugins.woocommerce.payments.name}}
                  </div>
                </div>
                <div class="case-cart__row custom-row">
                  <div class="case-cart__col custom-col case-cart__plugin-description">
                    {{$t(`${$store.getters.getDownloadPlugins.plugins.woocommerce.payments.short_description_localization_key}.localization_value.value`)}}
                  </div>
                </div>
              </div>
              <div class="case-cart__bottom">
                <div class="case-cart__btn"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <span class="admin-edit"
                          @click="editTranslate(['plugins_userManual'])"></span>
                  <a target="_blank" :href="$store.getters.getDownloadPlugins.plugins.woocommerce.payments.user_manuals_link[$store.getters.GET_LANG]">
                    <span class="link-button case-cart__plugin-btn">{{ $t('plugins_userManual.localization_value.value') }}</span>
                  </a>
                </div>
                <div class="case-cart__btn"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <span class="admin-edit"
                          @click="editTranslate(['plugins_download'])"></span>
                  <CaseCartButton
                          :value="$t('plugins_download.localization_value.value')"
                          :ico="true"
                          @click.native="downloadPlugin($store.getters.getDownloadPlugins.plugins.woocommerce.payments)"
                  >
                    <template slot="ico">
                      <DownloadIco/>
                    </template>
                  </CaseCartButton>
                </div>
              </div>
            </div>
          </div>
        </div>
        </template>
      </template>



    </div>

  </div>
</template>

<script>
  import CaseCartButton from "../../../../../../UI/buttons/CaseCartButton/CaseCartButton";
  import DownloadIco from "../../../../../../../../public/img/UI-group/download-icon.svg?inline"

  export default {
    name: "DownloadPluginsUserTable",
    components: {
      CaseCartButton,
      DownloadIco,
    },

    props: ['filter'],

    data() {
      return {
        openCartIco: '/img/company-icons-group/tab-m-opencart.png',
        wooСommerceIco: '/img/company-icons-group/tab-m-woo-commerce.png',

        list: [
          {
            namePlugin: 'Order',
            captionPlugin: 'SkladUSA_order_wp1.0',
            ico: '/img/company-icons-group/tab-m-opencart.png',
            linkToTheManual: 'google.com',
          },
          {
            namePlugin: 'Order',
            captionPlugin: 'SkladUSA_order_wp1.0',
            ico: '/img/company-icons-group/tab-m-woo-commerce.png',
            linkToTheManual: 'google.com',
          },
          {
            namePlugin: 'Order',
            captionPlugin: 'SkladUSA_order_wp1.0',
            ico: '/img/company-icons-group/tab-t.png',
            linkToTheManual: 'google.com',
          },
        ]
      }
    },

    mounted() {

    },

    methods: {
      downloadPlugin(plugin) {
        this.$store.dispatch('getDownloadPlugins', plugin.download_route).then((response) => {
          const blob = new Blob([response.data], { type: 'application/zip' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = plugin.file_name
          link.click()
          URL.revokeObjectURL(link.href)
        }).catch(error => this.createErrorLog(error))
      },

      downloadPluginPrinter() {
        const link = document.createElement('a')
        link.href = '/SkladPrint.zip'
        link.download = 'SkladPrint.zip'
        link.click()
        URL.revokeObjectURL(link.href)
      },
    },


  }
</script>

<style lang="scss">

  @import "./DownloadPluginsUserTable";


</style>
